import React from 'react';
import TableC from '../Component/Table/TableC';
import { TableColumn } from '../Component/Table/interface';
import { SORT_TYPE } from '../Component/Table/enum';

const columns : TableColumn[] = [
	{
		label: 'Name',
		id: 'name',
		type: 'string',
		sort: SORT_TYPE.ASCE
	},
	{
		label: 'Funnel Name',
		id: 'funnel_name',
		type: 'string',
	},
	{
		label: 'Activation Status',
		id: 'activation_status',
		type: 'string',
	},
	{
		label: 'Website Goal',
		id: 'goal',
		type: 'string',
	},
	{
		label: 'Language',
		id: 'language',
		type: 'string',
	},
	{
		label: 'Type',
		id: 'type',
		type: 'string',
	},
	{
		label: 'Priority',
		id: 'priority',
		type: 'enum',
		map: [
			{
				label: 'NOTHING',
				value: 'nothing'
			},
			{
				label: 'PRIORITY100',
				value: 'priority100'
			},
			{
				label: 'PRIORITY150',
				value: 'priority150'
			},
			{
				label: 'LOW',
				value: 'low'
			},
			{
				label: 'PRIORITY350',
				value: 'priority350'
			},
			{
				label: 'PRIORITY400',
				value: 'priority400'
			},
			{
				label: 'MEDIUM',
				value: 'medium'
			},
			{
				label: 'MEDIUM+',
				value: 'medium_plus'
			},
			{
				label: 'HIGH',
				value: 'high'
			},
			{
				label: 'HIGHEST',
				value: 'highest'
			},
			{
				label: 'HIGHEST+',
				value: 'highest_plus'
			},
		]
	},
	{
		label: 'WebMaster',
		id: 'webmaster',
		type: 'user',
	}
];	

const AllWebsites = () => {
  
  return (
    <>	  
        {<TableC id={'all_websites'} type='all_websites' useFilters={true} editable={true} columns={columns} allowUpload={true} />}
    </>
  );
};

export default AllWebsites;
