import React, { useEffect, useState, useRef, useMemo, FormEvent } from 'react';
import './TableC.css';
import { Dropdown, Toast, ToastContainer, Form, Button, Row, Col } from 'react-bootstrap';
import {
  uploadCSV, getUserInfo, downloadCSVSample
} from '../../shared/services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faUpload,
  faDownload,
  faCompress,
  faArrowDownUpAcrossLine,
  faEllipsis,
  faPencil,
  faUpLong, faDownLong, faCheckSquare, faSave
} from '@fortawesome/free-solid-svg-icons';
import {
  FilterColOptions,
  TableColumn,
  TableColumns,
  TableDataResponse,
  TableDataRow,
  GroupedTableData,
  userMap,
  RowActionValues,
  ServerUpdateResponse,
  buttonRenderFunc
} from './interface';
import { SORT_TYPE } from './enum';
import * as XLSX from 'xlsx';
import FiltersC, {FilterProps} from './FiltersC';
import SearchData from './SearchData';
import RowEditor from './RowEditor';
import { useDebounce } from "use-debounce";
import {
	useFetchData
  } from '../../shared/hooks/useFetchData';
import dayjs from "dayjs";
import {TableVirtuoso} from 'react-virtuoso'
let advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

interface tableProps  {
	type: string;
	useFilters: boolean;
	prefilter?: FilterProps[];
	useGroups?: boolean;
	editable?: boolean;
	allowUpload?: boolean;
	columns: Array<TableColumn>;
	id?: string | number;
	saveCallback?: (data: Map<number, RowActionValues>) => Promise<ServerUpdateResponse>;
	customGroupHeader?: (row: TableDataRow) => JSX.Element
}

interface userData {
	id: number,
	name: string,
	role: string,
	email: string,
	funnels: boolean
}

const priorities = new Map(['nothing', 'priority100', 'priority150', 'low', 'priority350', 'priority400', 'medium', 'medium_plus', 'high', 'highest', 'highest_plus'].map((p, idx) => [p, idx]));

const TableC = (props: tableProps) => {
  // const ref = useRef(null);
  const [boardName, setBoardName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [searchLoading, setSearchLoading] = React.useState<boolean>(true);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [csvFile, setCSVFile] = useState<File>();
  const uploaderRef = useRef(null);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [uploadInProgess, setUploadInProgess] = useState<boolean>(false);
  const [uploadNoteText, setUploadNoteText] = useState<string>('');

  const [loadTable, setLoadTable] = React.useState<boolean>(true);
  const [subTableStatus, setSubTableStatus] = React.useState<{[group: string] : boolean}>({});
  const [columns, setColumns] = useState<TableColumns>({});
  const [nameSortType, setNameSortType] = React.useState<SORT_TYPE>(
    SORT_TYPE.NA
  );
  const [tableSearchText, setTableSearchText] = useState<string>('');
  const [tableSearchTextTemp, setTableSearchTextTemp] = useState<string>('');
  const [debouncedTableSearchText] = useDebounce(tableSearchText, 500);
  // user role
  const [userInfo, setUserInfo] = useState<any>();
  const [userList, setUserList] = useState<userMap>({});
  //new
  const [tableData, setTableData] = React.useState<TableDataRow[] | GroupedTableData>(
    []
  );
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [toastErrorText, setToastErrorText] = useState<string>('');

  const [backUptableData, setBackUpTableData] = React.useState<
  TableDataRow[] | GroupedTableData
  >([]);

  const [tableFilterColumns, setTableFilterColumns] = React.useState<
    FilterColOptions[]
  >([]);

  const [actionColumnData, setActionColumnData] = useState<Map<number, RowActionValues>>(new Map());

  const [selectedRow, setSelectedRow] = useState<TableDataRow|null>(null);

  //sort column
  const sortColumn = async (colData: TableColumn, index: number) => {
    let temColState = {...columns};
    let tempColElement = { ...temColState[colData.id] };

    let sortType = SORT_TYPE.NA;
    if (
      colData.sort === SORT_TYPE.NA ||
      colData.sort === SORT_TYPE.DESC
    ) {
      sortType = SORT_TYPE.ASCE;
      tempColElement.sort = SORT_TYPE.ASCE;
    } else if (colData.sort === SORT_TYPE.ASCE) {
      sortType = SORT_TYPE.DESC;
      tempColElement.sort = SORT_TYPE.DESC;
    }
    temColState[tempColElement.id] = tempColElement;
    setColumns(temColState);

	if (props.useGroups) {
		let sortedData: GroupedTableData = [];
		for (const group of tableData as GroupedTableData) {
			sortedData.push({
				group_data: {
					...group.group_data
				},
				rows: await sortData(group.rows, colData, sortType)
			})

		}
		setLoadTable(false);
		setTableData(sortedData);
	} else {
		let sortedData: TableDataRow[] = [];
		sortedData = await sortData(tableData as TableDataRow[], colData, sortType);
		setLoadTable(false);
		setTableData(sortedData);
	}
  };

  const sortData = async (data: TableDataRow[], colData: TableColumn, sortType: string) => {
	let sorted = await data.sort((a, b) => {
		setLoadTable(true);
  
		let op_1 = a[colData.id];
		let op_2 = b[colData.id];
		let value_1;
		let value_2;
		if (colData.id === 'priority') {
			op_1 = priorities.get(op_1 as string) ?? 0;
			op_2 = priorities.get(op_2 as string) ?? 0;
			return sortType === SORT_TYPE.ASCE ? op_1 - op_2 : op_2 - op_1;
		}
		if (colData.type !== 'number') {
		  //EVG NOTE: The sort implementataion might need to be reviewd
		  op_1 = (op_1 as string).replace(/[$£¥€₪,]/g, '');
		  op_2 = (op_2 as string).replace(/[$£¥€₪,]/g, '');
  
  
		  if (op_1 === '-') {
			  op_1 = '';
		  }
		  if (op_2 === '-') {
			  op_2 = '';
		  }
  
		  if (op_1 === 'null' || op_1 === 'Free') {
			  return sortType === SORT_TYPE.ASCE ? -1 : 1;
		  }
		  if (op_2 === 'null' || op_2 === 'Free') {
			  return sortType === SORT_TYPE.ASCE ? 1 : -1;
		  }
		  value_1 = op_1 === null ? '' : op_1;
		  value_2 = op_2 === null ? '' : op_2;
  
		} else {
		  value_1 = op_1 === null ? 0 : new Number(op_1);
		  value_2 = op_2 === null ? 0 : new Number(op_2);
		}
		  if (value_1 > value_2) {
			  return sortType === SORT_TYPE.ASCE ? 1 : -1;
		  } else if (value_2 > value_1) {
			  return sortType === SORT_TYPE.ASCE ? -1 : 1;
		  } else {
			  return 0;
		  }
	  });
	  return sorted;
  }

  const hideColumn = async (colData: TableColumn) => {

    let data = {...columns};
	let newColumnData: TableColumn = {
		...colData,
		visible: !colData.visible
	}
	data[newColumnData.id] = newColumnData;
    setColumns(data);
  }

  const selectAllCheckboxes = (colData: TableColumn) => {
	updateAllActionData(colData.id, 1);
  }

  const handleDropdownApplySelected = (e: FormEvent) => {
	e.preventDefault();
	e.stopPropagation();
	let form = e.currentTarget as HTMLFormElement;
	const formData = new FormData(form);
	let colId = formData.get('dropdown_col_id') as string;
	const tiedColId = columns[colId].tiedCheckboxColumn;
	const selectedVal = formData.get('col_dropdown_' + colId) as string;
	updateAllActionData(colId, selectedVal, tiedColId);
  }	

  const updateAllActionData = (colId: string, value: string | number | null, tiedColId: string | null = null) => {
	const updated = new Map<number, RowActionValues>(actionColumnData);
	if (props.useGroups) {
		for (const group of tableData as GroupedTableData) {
			for (const row of group.rows) {
				let currActionData = actionColumnData.get(row.id as number) as RowActionValues;
				if (currActionData && (tiedColId ? currActionData[tiedColId as string] : true)) {
					let updatedActionData = {...currActionData, [colId]: value};
					updated.set(row.id as number, updatedActionData);
				} else {
					updated.set(row.id as number, currActionData);
				}
			}
		}
	} else {
		for (const row of tableData as TableDataRow[]) {
			let currActionData = actionColumnData.get(row.id as number) as RowActionValues;
			if (currActionData && (tiedColId ? currActionData[tiedColId as string] : true)) {
				let updatedActionData = {...currActionData, [colId]: value};
				updated.set(row.id as number, updatedActionData);
			} else {
				updated.set(row.id as number, currActionData);
			}
		}
	}
	setActionColumnData(updated);
  }

  const sortByName = async (data: TableDataRow[], sortType: string) => {
	let sorted = await data.sort((a, b) => {
		setLoadTable(true);
  
		let op_1 = a.name;
		let op_2 = b.name;
  
		if (op_1 > op_2) {
		  return sortType === SORT_TYPE.ASCE ? 1 : -1;
		} else if (op_2 > op_1) {
		  return sortType === SORT_TYPE.ASCE ? -1 : 1;
		} else {
		  return 0;
		}
	  });
	return sorted;  
  }

  //sort name Column
  const sortNameColumn = async () => {
	let sortType = SORT_TYPE.NA;
	if (
		nameSortType === SORT_TYPE.NA ||
		nameSortType === SORT_TYPE.DESC
	  ) {
		setNameSortType(SORT_TYPE.ASCE);
		sortType = SORT_TYPE.ASCE;
	  } else {
		setNameSortType(SORT_TYPE.DESC);
		sortType = SORT_TYPE.DESC;
	  }
	if (props.useGroups) {
		let sortedData: GroupedTableData = [];
		for (const group of tableData as GroupedTableData) {
			sortedData.push({
				group_data: {
					...group.group_data
				},
				rows: await sortByName(group.rows, sortType)
			})

		}
		setTableData(sortedData);
		setLoadTable(false);
	} else {
		let tempData = tableData as TableDataRow[];
		let sortedData = await sortByName(tempData, sortType);
		setTableData([...sortedData]);
		setLoadTable(false);
	}
  };

const processCellValue = (cellValue: string | number | null , colId: string) : string | number | null  => {
	let proccedValue = cellValue;
	if (columns[colId].type === 'number') {
		proccedValue = columns[colId].subType === 'percent' ? 
		Intl.NumberFormat('en-US', {
			style: 'percent', 
			minimumFractionDigits: 2, 
			maximumFractionDigits: 2}
			).format((cellValue as number) / 100) : 
		Intl.NumberFormat().format(cellValue as number);
	}
	else if (columns[colId].type === 'checkbox') {
		proccedValue = proccedValue ? 'Yes' : 'No';
	}
	else if ((columns[colId].type === 'enum' || columns[colId].type === 'dropdown') && proccedValue) {
		let mappedVal = columns[colId].map?.filter((opt) => {return cellValue === opt.value});
		proccedValue = mappedVal?.length ? mappedVal[0].label as string : null;
		// proccedValue = columns[colId].map?.[cellValue as number | string] as string;
	}
	else if (columns[colId].type === 'date') {
		proccedValue = dayjs(cellValue).format('MMMM Do YYYY, HH:mm:ss');
	}
	else if (columns[colId].type === 'user') {
		proccedValue = cellValue ? userList[cellValue as number] : null;
	}
	return proccedValue;
}  

const handleActionColumnChange = (value:any, row: TableDataRow, column: string) => {
	let actionData = { ...actionColumnData.get(row.id as number)};
	actionData[column] = value;
	setActionColumnData(new Map(actionColumnData.set(row.id as number, actionData)));
}

const getActionColumnValue = (row: TableDataRow, column: string) => {
	if (actionColumnData.has(row.id as number)) {
		return (actionColumnData.get(row.id as number) as RowActionValues)[column];
	} else {
		return row[column];
	}
}

const hideToastError = () => {
	setToastErrorText('');
	setShowToastError(false);
}
	
  const handleCellButton = (row: TableDataRow, col: TableColumn) => {
	if (col.subType === 'row_action') {
		(col.buttonAction as (r: TableDataRow) => Promise<null|TableDataRow>)(row as TableDataRow).then((newRow) => {
			doFetch(props.type, props.id as string | number, usedFilters);
		})
	}
	if (col.subType === 'general_action') {
		(col.buttonAction as (r: TableDataRow) => void)(row as TableDataRow);
	}
  }

  const renderRow = (row: TableDataRow, i: number) => {
	if (props.useGroups && row.group_header_row === 1) {
		if (props.customGroupHeader) {
			return props.customGroupHeader(row);
		} else {
			return <td className='virt-table-cell group-header-cell default-group-header' colSpan={Object.keys(columns).length + (props.editable ? 1 : 0)}>{row.name}</td>
		}
		
	} else {
		return <>
				{props.editable ? (
					<td className="virt-table-cell editCell">
						<FontAwesomeIcon
							icon={faPencil}
							className="edit-icon"
							onClick={() => editRow(row)}
						/>
					</td>) : null}
				{<>
					<td className="virt-table-cell name-cell">{row.name} </td>
					{Object.keys(columns).map((key, j) => {
						if (columns && columns[key] && key !== 'name') {
							if (columns[key] && columns[key].visible === false) {
								return <td className="virt-table-cell">.</td>
							} else {
								if ((row[key] === 'null' || row[key] === null) && !['checkbox', 'dropdown'].includes(columns[key].type)) {
									return <td className="virt-table-cell"></td>;
								} else {
									let cellValue = processCellValue(row[key], key);
								if (columns[key].type === 'string' && row[key] && (row[key] as string).length > 10) {
									return (
										<td className="virt-table-cell tooltipp ">
											{(cellValue as string).substring(0, 10) + '...'}
											<span className="tooltipptext">
												{cellValue}
											</span>
										</td>
									);
								} else if (columns[key].type === 'checkbox') {
									return (
										<td className="virt-table-cell checkbox-cell">
											<input 
												type='checkbox' 
												name={`${columns[key].id}[${row['id']}]`} 
												checked={getActionColumnValue(row, columns[key].id) as number > 0 ? true : false} 
												onChange={(e) => handleActionColumnChange(e.target.checked, row, columns[key].id)} 
											/> 
										</td>
									);	
								} else if (columns[key].type === 'dropdown') {
									return (
										<td className="virt-table-cell">
											<select
												onChange={(e) => handleActionColumnChange(e.target.value, row, columns[key].id)}
												value={getActionColumnValue(row, columns[key].id) as any}
											>
												{/* <option value={''}>Select {columns[key].label}</option> */}
												{columns[key].map?.map((opt) => <option value={opt.value}>{opt.label}</option>)}
											</select>
										</td>
									);	
								} else if (columns[key].type === 'button') {
									return (
										<td className="virt-table-cell button-cell">
											<button className={'btn ' + (columns[key].buttonClass ?? '')} onClick={() => handleCellButton(row, columns[key])}>
												{(columns[key].renderFunc as buttonRenderFunc)(row)}
											</button>
										</td>
									)
								} else {
									return <td className="virt-table-cell">{cellValue}</td>;
								}
								}
							}
						} else {
							return null;
						}
					})}
				</>} 
			</>
	}
	
  }

  //TODO check how to resolve fixed header width
  const renderHeaderRow = () => {
	return <tr>
		{props.editable ? (<th className="virt-table-col edit-cell">Edit</th>) : null}
		<th className="virt-table-col" onClick={() => sortNameColumn()}>
			{columns.name.label + ' '}
			<span>
				{nameSortType === SORT_TYPE.NA ||
					nameSortType === SORT_TYPE.DESC ? (
					<FontAwesomeIcon icon={faUpLong} />
				) : (
					<FontAwesomeIcon icon={faDownLong} />
				)}
			</span>
		</th>
		{
			Object.values(columns).map((colData, i) => {
				if (colData.id !== 'name') {
					return (
						<th className={'virt-table-col' + (colData.type === 'button' ? ' button-col' : '')} key={`tableDataColumns_${i}`}>
							<div className='flexcol'>
							<div>{colData.visible === true ? String(colData.label) + ' ' : ' '}</div>
							<div className="dropdown">
								<button className="dotBtn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
								<FontAwesomeIcon icon={faEllipsis} />
								</button>
		
								<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
								<li>
									<div className="dropdown-item">
									{colData.visible === true
										? (
										<button className='btn' onClick={() => hideColumn(colData)}><FontAwesomeIcon icon={faCompress} /> <span>Collapse</span></button>
										) : (
										<button className='btn' onClick={() => hideColumn(colData)}><FontAwesomeIcon icon={faCompress} /> <span>Uncollapse</span></button>
										)}
									</div>
								</li>
								{
									(Object.hasOwn(colData, "sortable") && colData.sortable || !Object.hasOwn(colData, "sortable")) ?
									<li>
										<div className="dropdown-item" onClick={async () => { await sortColumn(colData, i); }}>
										{colData.sort === SORT_TYPE.NA ||
											colData.sort === SORT_TYPE.DESC ? (
											<button className='btn'><FontAwesomeIcon icon={faArrowDownUpAcrossLine} /> <span>Sort</span></button>
										) : (
											<button className='btn'><FontAwesomeIcon icon={faArrowDownUpAcrossLine} /> <span>Sort</span></button>
										)}
										</div>
									</li>
								: null
								}
								{
									colData.type === 'checkbox' ? 
									<div className="dropdown-item">
										<li>
											<button className='btn' onClick={() => selectAllCheckboxes(colData)}><FontAwesomeIcon icon={faCheckSquare} /> <span>Select All</span></button>
										</li>
									</div>
									: ''
								}
								{
								(colData.type === 'dropdown' && colData.tiedCheckboxColumn) ? 
									<div className="dropdown-item dropdown-col-header">
										<Form onSubmit={handleDropdownApplySelected}>
											<Row>
												<Col xs={12}>
													<select
													name={`col_dropdown_${colData.id}`}
													>
														{colData.map?.map((opt) => <option value={opt.value}>{opt.label}</option>)}
													</select>
													<input type='text' name='dropdown_col_id' value={colData.id} hidden />
												</Col>
												<Col>
													<Button variant='secondary' className='mt-2' type='submit'><div style={{width: '100%'}}>Apply to selected</div></Button>
												</Col>
											</Row>
										</Form>
									</div>

								: null
								}
								</ul>
							</div>
							</div>
		
						</th>
					)
				} else return null;
			})
		}
		
	</tr>
	
  }

  //search table data
  const searchTableData = () => {
	  let searchString = debouncedTableSearchText.toLowerCase();
	  if (props.useGroups) {
		let filterdData: GroupedTableData = [];
		for (const group of backUptableData as GroupedTableData) {
			const filteredRows = (group.rows as TableDataRow[]).filter((item: TableDataRow) => {
				return JSON.stringify(item)
				  .toLowerCase()
				  .includes(searchString);
			})
			if (filteredRows.length) {
				filterdData.push({
					group_data: {
						...group.group_data
					},
					rows: filteredRows
				})
			}
		}
		setTableData(filterdData);
	  } else {
		let result = (backUptableData as TableDataRow[]).filter((item: TableDataRow) => {
			return JSON.stringify(item)
			  .toLowerCase()
			  .includes(searchString);
		  });
		  setTableData([...result]);
	  }	
	  setLoadTable(false); 
  };

  useEffect(() => {
	searchTableData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTableSearchText])

  const [usedFilters, setUsedFilters] = useState<any[]>([]);
  const {data, doFetch} = useFetchData();
  const actionColumns = useMemo(() => {
	let cols = [];
	for (const col in columns) {
		if (columns[col].type === 'checkbox' || columns[col].type === 'dropdown') {
			cols.push(columns[col].id);
		}
	}
	return cols;
  }, [columns])
 
  const processData = (data: TableDataResponse) => {
	setBoardName(data.board_name);
	setTableData(data.rows);
	setBackUpTableData(data.rows);
	processActionData(data.rows);
	if (props.useGroups) {
		const initialStatus : {[group: string] : boolean} = {};
		for (const groupName in data.rows) {
			initialStatus[groupName as string] = true;
		}
		setSubTableStatus(initialStatus);
	}
	setSearchLoading(false);
	setLoadTable(false);
  }

  const processActionData = (data: TableDataRow[] | GroupedTableData) => {
	if (actionColumns.length) {
		const updated = new Map<number, RowActionValues>();
		if (props.useGroups) {
			for (const group of data as GroupedTableData) {
				for (const row of group.rows) {
					updated.set(row.id as number, processActionRow(row));
				}
			}
		} else {
			for (const row of data as TableDataRow[]) {
				updated.set(row.id as number, processActionRow(row));
			}
		}
		setActionColumnData(updated);
	}
  }

  const processActionRow = (row: TableDataRow): RowActionValues => {
	let actionData: RowActionValues = {};
	for (const actionColumn of actionColumns) {
		actionData[actionColumn] = row[actionColumn];
	}
	return actionData;
  }	

  const processColumns = () => {
	let filterTempData: FilterColOptions[] = [];
	let columnObject: TableColumns = {};
	props.columns.forEach((col) => {
		columnObject[col.id] = {
			...col,
			sort: col.hasOwnProperty('sort') ? col.sort : SORT_TYPE.NA,
			visible: col.hasOwnProperty('visible') ? col.visible : true 
		};
		if ((Object.hasOwn(col, 'filterable') && col.filterable) || !Object.hasOwn(col, 'filterable')) {
			let filterDataObj: FilterColOptions = {
				value: col.id,
				label: col.label,
				// id: col.id,
				type: col.type
			};
			if (col.map) {
				filterDataObj.valMap = col.map
			}
			filterTempData.push(filterDataObj);
		}
		
	})
	setColumns(columnObject);
	setTableFilterColumns(filterTempData);
  }

  useEffect(() => {
	let isMounted = true;
	if (isMounted) {
		processColumns();
	}
	return () => {
		isMounted = false;
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  useEffect(() => {
	let isMounted = true;
	setTableData([]);
	const asyncFetch = async () => {
		await doFetch(props.type, props.id as string | number, props.prefilter ?? []);
	}
	if (props.id?.toString().length) {
		if (isMounted) {
			setLoadTable(true);
			asyncFetch();
		}
		
	}
	return () => {
		isMounted = false;
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => {
	let isMounted = true;
	if (isMounted) {
		processData(data);
		setLoading(false);
		setSearchLoading(false);
	}
	return () => {
		isMounted = false;
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //download the file
  const dataDownload = () => {
	if (tableData.length) {
		const processedData = [];		
		for (const row of tableData as TableDataRow[]) {
			let processedRow: {[key:string] : string | number | null} = {...processExportRow(row)};
			delete processedRow['id'];
			processedData.push(processedRow);
		}
		exportProcessedData(processedData);
	}
  };

  const dataDownloadGrouped = () => {
	const processedData = [];	
	if (tableData.length) {
		for (const group of tableData as GroupedTableData) {
			for (const row of group.rows) {
				let processedRow: {[key:string] : string | number | null} = {...processExportRow(row)};
				delete processedRow['id'];
				processedData.push(processedRow);
			}
		}
		exportProcessedData(processedData);
	}
  };

  const getExportColumnNames = () => {
	let colNames = [];
	for (const colId in columns) {
		if (!['button'].includes(columns[colId].type)) {
			colNames.push(columns[colId].label ?? columns[colId].id);
		}
	}
	return colNames;
  }

  const processExportRow = (row: TableDataRow) => {
	let processedRow: TableDataRow = {
		id: 0,
		name: ''
	};
	for (const colId in columns) {
		if (!['button'].includes(columns[colId].type)) {
			processedRow[colId] = processCellValue(row[colId], colId);
		} else {
			delete processedRow[colId]
		}
	}
	return processedRow;
  }

  const exportProcessedData = (processedData: any[]) => {
	let colNames = getExportColumnNames();
	const wb = XLSX.utils.book_new();
	const ws = XLSX.utils.json_to_sheet(processedData);
	XLSX.utils.sheet_add_aoa(ws, [colNames], { origin: "A1" });
	XLSX.utils.book_append_sheet(wb, ws, 'Export');
	let currDate = new Date().toISOString();
	let fileName = props.type  + '_' + boardName + '_' + currDate.substring(0, 10) + '.xlsx';
	XLSX.writeFile(wb, fileName);
  }

  // User parsing
  useEffect(() => {
	let isMounted = true;
    let user = localStorage.getItem('user_info');

    if (user && isMounted) {
	  const parsedUser = JSON.parse(user);
	  if (parsedUser.role_id === 2) {
		let userList: userMap = {};
		getUserInfo().then((beUsers) => {
			beUsers.data.forEach((userData: userData) => {
				userList[userData.id] = userData.name;
			});
			setUserList(userList)
		})
	  }
      setUserInfo(parsedUser);
    }
	return () => {
		isMounted = false;
	}
  }, []);

  useEffect(() => {
	let isMounted = true;
	if (isMounted) {
    	setTableSearchText(tableSearchTextTemp);
		setLoadTable(true);
	}
	return () => {
		isMounted = false;
	}
  }, [tableSearchTextTemp])



  const onChangeFilters = async (filterOptions: any[]) => {
	setLoadTable(true);
	setUsedFilters(filterOptions);
	await doFetch(props.type, props.id as string | number, filterOptions);
  }

  function handleCSVUpload(e: React.ChangeEvent<HTMLInputElement>) {
	if (e.target && e.target.files && e.target.files[0]) {
		const file = e.target.files[0];
		if (file.type !== 'text/csv') {
			setCSVFile(undefined);
			setUploadError(true);
			setUploadNoteText('File must use a CSV format');
		}
		else if (file.size > 5242880) {
			setCSVFile(undefined);
			setUploadError(true);
			setUploadNoteText('File size is too large');
			return;
		} else {
			setUploadError(false);
			setUploadNoteText('');
			setCSVFile(file);
		}
	} else {
		setCSVFile(undefined);
		setUploadNoteText('');
	}
  }

  const uploadCSVFile = async () => {
	if (csvFile && !uploadError) {
		const formData = new FormData();
		formData.append("file", csvFile);
		if (props.type === 'order_final') {
			formData.append("board_id", props.id as string);
		}
		setUploadNoteText('Uploading..');
		setUploadInProgess(true);
		uploadCSV(props.type, formData).then((res) => {
			setCSVFile(undefined);
			if (res.error) {
				setUploadError(true);
				setUploadNoteText(res.message);
			} else {
				setLoadTable(true);
				setUploadNoteText('File Uploaded, refreshing..')
				setCSVFile(undefined);
				if (uploaderRef.current) {
					(uploaderRef.current as HTMLInputElement).value = '';
				}
				setUsedFilters([]);
				doFetch(props.type, props.id as string | number, []).then(() => {
					setLoading(false);
					setSearchLoading(false);
					setUploadNoteText('')
				});
			}
			setUploadInProgess(false);
		})
	}
  }

  const downloadSample = async () => {
	await downloadCSVSample(props.type).then((res) => {
		const fileName = props.type + '_sample.csv';
		let blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
	
		if ((window.navigator as any).msSaveBlob) { // IE 10+ support
			(window.navigator as any).msSaveBlob(blob, fileName);
		} else {
			let link = document.createElement("a");
			if (link.download !== undefined) {
				let url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", fileName);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	})
  }

  const editRow = (row: TableDataRow) => {
	setSelectedRow(row);
  }

  const onEditRow = async () => {
	setSelectedRow(null);
	doFetch(props.type, props.id as string | number, usedFilters);
  }

  const onSaveTable = () => {
	props.saveCallback?.(actionColumnData).then((res) => {
		if (res.error) {
			setToastErrorText(res.message);
			setShowToastError(true);
		} else {
			doFetch(props.type, props.id as string | number, usedFilters);
		}
	})
  }

  const flattenGroupedData = (groupedData: GroupedTableData): TableDataRow[] => {
	const flatTableData: TableDataRow[] = [];
	for (const dataGroup of groupedData as GroupedTableData) {
		flatTableData.push({
			...dataGroup.group_data,
			group_header_row: 1
		});
		for (const row of dataGroup.rows) {
			flatTableData.push({...row});
		}
	}
	return flatTableData; 
  }

  return (
      <>
          {searchLoading ? <div className="text-center filterImage">
            <img src="/Spinner-1s-200px.gif" alt="" />
          </div> : null}
		  <div className='container' style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
				<div className='row'>
					<div className='col-md-12'>
						<div className='boardname'><p className="tableHeading text-center">{boardName}</p></div>
					</div>
					<div className='col-md-12 filterPanel'>
						<div className='d-flex justify-content-between'>
							<div className="leftElement">
								<ul>
									<li className="searchBox">
										<FontAwesomeIcon
											icon={faSearch}
											className="search-icon"
										/>
										<SearchData tableSearchTextTemp={tableSearchTextTemp} setTableSearchTextTemp={setTableSearchTextTemp} />
										{props.useFilters ? 
											<FiltersC 
												{...(props.prefilter && ({prefilter: props.prefilter}))}
												dataLoading={loadTable} 
												tableId={props.id} 
												type={props.type} 
												columns={tableFilterColumns} 
												useGroups={props.useGroups ?? false} 
												onChangeFilters={onChangeFilters} 
												tableData={tableData as TableDataRow[]} /> : '' }
									</li>
								</ul>		
							</div >

							<div className="rightElement">
								<ul>
								<li>
									<button
									className="user-name btn exportBtn"
									type="button"
									onClick={() => props.useGroups ? dataDownloadGrouped() : dataDownload()}
									>
									<FontAwesomeIcon
										icon={faDownload}
										className="user-icon"
									/>{' '}
									Export
									</button>
								</li>
								{props.allowUpload ? (
								<li>
									<Dropdown show={showUpload} onToggle={(isOpen) => setShowUpload(isOpen)} id='csvUploadPanel'>
										<Dropdown.Toggle
											className="user-name btn importBtn"
										>
										<FontAwesomeIcon
											icon={faUpload}
											className="user-icon"
										/>{' '}
										Upload CSV
										</Dropdown.Toggle>
										<Dropdown.Menu align={'end'}>
											<div className="csvUploader">
												<div className="container mb-3">
													<div className="row uploaderHeading">
														<div className="col-md">
															Select CSV file to upload (Max 5MB):
														</div>
													</div>
													<div className='row'>
														<div className='col csv-warning'>
															Note: Must be a valid CSV file with a single table. The first row should contain the column names from the sample file.
														</div>
													</div>
													{uploadNoteText ? (<div className={'row uploadNote' + (uploadError ? ' uploadError' : '')}>
														<div className="col">
															{uploadNoteText}
														</div>
													</div>) : ''}	
													<div className="row mb-3 mt-2">
														<div className="col-md">
															<input ref={uploaderRef} type='file' accept='.csv' id='csvFileUploader' onChange={(e) => handleCSVUpload(e)} />
														</div>
													</div>
													<div className="row m justify-content-end">
														<div className='col tableBtnCont'>
															<button
																className="user-name btn sampleBtn"
																type="button"
																onClick={downloadSample}
															>
																Download Sample
															</button>
															<button
																className="user-name btn uploadBtn"
																type="button"
																disabled={typeof csvFile === 'undefined' || csvFile?.size === 0 || uploadError || uploadInProgess}
																onClick={uploadCSVFile}
															>
																Upload File
															</button>
														</div>
													</div>
												</div>
											</div>
										</Dropdown.Menu>
									</Dropdown>
									
								</li>
								) : ''}
								{typeof props.saveCallback != "undefined" ? (
									<li>
										<button
										className="user-name btn btn-success tbl-save-btn"
										type="button"
										onClick={() => onSaveTable()}
										>
										<FontAwesomeIcon
											icon={faSave}
											className="user-icon"
										/>{' '}
										Save
										</button>
										<ToastContainer position='bottom-end' style={{ zIndex: 9999 }}>
											<Toast show={showToastError} onClose={hideToastError}>
												<Toast.Header>
													<strong className="me-auto" style={{color: 'red'}}>Error</strong>
												</Toast.Header>
												<Toast.Body>{toastErrorText}</Toast.Body>
											</Toast>
										</ToastContainer> 
									</li>
								) : ''}
								</ul>
							</div>
						</div >
						<RowEditor type={props.type} columns={columns} userList={userList} dataRow={selectedRow} onEditRow={onEditRow} />
					</div>
				</div>			
				{
					loading ? (
					<div className="col-md-12 text-center loadingImage " >
						<img src="/Spinner-1s-200px.gif" alt="" />
					</div>
					) : (
						<div className="container" style={{flex: '1 0 auto'}}>
							<div className="tableSize" style={{height: '100%'}}>
								{(loadTable) ? (
								<div className="tableLoadingColor text-center">
									<img src="/Spinner-1s-200px.gif" alt="" />
								</div>
								) : null}
								{
									props.useGroups ?
										<TableVirtuoso 
											style={{height: '100%'}}
											data={flattenGroupedData(tableData as GroupedTableData)}
											fixedHeaderContent={() => renderHeaderRow()}
											itemContent={(index, row) => renderRow(row, index)}
										/>
									: 
									<TableVirtuoso 
										style={{height: '100%'}}
										data={tableData as TableDataRow[]}
										fixedHeaderContent={() => renderHeaderRow()}
										itemContent={(index, row) => renderRow(row, index)}
									/>
								}
							</div>
						</div>
					)
				}
		  </div>
		</>
  );
};

export default TableC;
