import { useEffect, useState, FormEvent } from 'react';
import './Plans.css';
import { Container, Row, Col, Stack, Modal, Dropdown, Button, Form, InputGroup } from 'react-bootstrap';
import FiltersC from '../Table/FiltersC';
import { useFetchData } from '../../shared/hooks/useFetchData';
import {
	getPlans,
    updatePlan,
    createPlan,
    deletePlan
} from '../../shared/services/api';
import {
FilterColOptions,
TableDataRow
} from '../Table/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFolderPlus, faClose, faPlusCircle, faTrash
} from '@fortawesome/free-solid-svg-icons';


type PlansProps = {
    open: boolean,
    onClose: (refresh: boolean) => void
}

export type PlanData = {
    id: number,
    name: string,
    params: {
        max_no_follow: number,
        no_follow_max_price: number,
        no_follow_min_dr: number,
        max_links_amount: number,
        min_links_amount: number,
        max_price_budget_percent: number,
        left_over_budget_percent: number,
        anchor_text_natural_percent: number,
        anchor_text_urls_percent: number,
        anchor_text_funnel_name_percent: number,
        dr_settings: Array<{
            from: number | null,
            to: number | null,
            percent: number,
        }>,
    },
    priority: string | null,
    default: boolean,
    filterOptions: any[]
}

const columns : FilterColOptions[] = [
    {
        label: 'Name',
        value: 'name',
        type: 'string',
    },
    {
        label: 'DR',
        value: 'dr',
        type: 'number',
    },
    {
        label: 'RD',
        value: 'rd',
        type: 'number',
    },
    {
        label: 'LD',
        value: 'ld',
        type: 'number',
    },
    {
        label: 'Traffic Since',
        value: 'traffic_since',
        type: 'number',
    },
    {
        label: 'Traffic',
        value: 'traffic',
        type: 'number',
    },
    {
        label: 'Old Traffic',
        value: 'old_traffic',
        type: 'number',
    },
    {
        label: 'Traffic Difference',
        value: 'traffic_difference',
        type: 'number',
    },
    {
        label: 'Niche',
        value: 'niche',
        type: 'string',
    },
    {
        label: 'Countries',
        value: 'countries',
        type: 'string',
    },
    {
        label: 'Final Price',
        value: 'final_price',
        type: 'number',
    },
    {
        label: 'Location',
        value: 'location',
        type: 'string',
    },
    {
        label: 'Language',
        value: 'language',
        type: 'string',
    },
    {
        label: 'Last Updated',
        value: 'updated_at',
        type: 'date',
    },
    {
        label: 'Contact Info',
        value: 'contact_info',
        type: 'string',
    },
    {
        label: 'Continuous/Discontinued',
        value: 'continuous',
        type: 'string'
    },
    {
        label: 'Type',
        value: 'type',
        type: 'string',
    },
    {
        label: 'Currency',
        value: 'currency',
        type: 'string',
    },
    {
        label: 'Bulk Orders',
        value: 'bulk_orders',
        type: 'string',
    },
    {
        label: 'Last 3 Months',
        value: 'last_3_months',
        type: 'string',
    },
    {
        label: 'Last 3 Months Deals',
        value: 'last_3_months_deals',
        type: 'number',
    },
    {
        label: 'Total',
        value: 'total',
        type: 'number',
    }
];	

const priorityOpts = [
    {
        label: 'NOTHING',
        value: 'nothing'
    },
    {
        label: 'PRIORITY100',
        value: 'priority100'
    },
    {
        label: 'PRIORITY150',
        value: 'priority150'
    },
    {
        label: 'LOW',
        value: 'low'
    },
    {
        label: 'PRIORITY350',
        value: 'priority350'
    },
    {
        label: 'PRIORITY400',
        value: 'priority400'
    },
    {
        label: 'MEDIUM',
        value: 'medium'
    },
    {
        label: 'MEDIUM+',
        value: 'medium_plus'
    },
    {
        label: 'HIGH',
        value: 'high'
    },
    {
        label: 'HIGHEST',
        value: 'highest'
    },
    {
        label: 'HIGHEST+',
        value: 'highest_plus'
    },
];

const Plans = ({open, onClose}: PlansProps) => {
    const {data, doFetch} = useFetchData();
    const [loading, setLoading] = useState<boolean>(true);
    const [plans, setPlans] = useState<any[]>([]); //TODO give proper type
    const [selectedPlanIndex, setSelectedPlanIndex] = useState<number>(-2);
    const [selectedPlanData, setSelectedPlanData] = useState<PlanData>();
    const [planName, setPlanName] = useState<string>('');
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [validName, setValidName] = useState<boolean>(true);
    const [invalidAnchors, setInvalidAnchors] = useState<boolean>(false);
    const [invalidDr, setInvalidDr] = useState<boolean>(false);
    useEffect(() => {
       
        if (open) {
            doFetch('stash', 'stash', []).then(() => {
                getPlans().then((res) => {
                    setPlans(res.data);
                    setLoading(false);
                })
            })
        }
        
    }, [open]);

    const updatePlanFilters = (filterData: any[]) => {
        setSelectedPlanData({
            ...selectedPlanData as PlanData,
            filterOptions: filterData
        })
        return new Promise<void>((resolve) => {
            resolve();
        })
    }

    const selectPlan = (index: number) => {
        setSelectedPlanIndex(index);
        setSelectedPlanData(JSON.parse(JSON.stringify(plans[index])));
        setShowValidation(false);
        setInvalidAnchors(false);
        setInvalidDr(false);
    }

    const handleNewPlan = () => {
        setShowValidation(false);
        setSelectedPlanIndex(-1);
        setSelectedPlanData(undefined);
    }

    const handlePlanNameChange = (name: string) => {
        setValidName(true);
        if (selectedPlanIndex > -1) {
            setSelectedPlanData(
                {
                    ...selectedPlanData as PlanData,
                    name: name
                }
            )
        } else {
            setPlanName(name)
        }
    }

    const handlePlanChange = (field: string, value: number | string) => {
        setSelectedPlanData(
            {
                ...selectedPlanData as PlanData,
                params: {
                    ...(selectedPlanData as PlanData)?.params,
                    [field]: value as number
                }
            }
        )
    }

    const handlePriorityChange = (value: string) => {
        setSelectedPlanData(
            {
                ...selectedPlanData as PlanData,
                priority: value
            }
        )
    }

    const handleDefault= (value: boolean) => {
        setSelectedPlanData(
            {
                ...selectedPlanData as PlanData,
                default: value
            }
        )
    }

    const handlePlanDrChange = (i: number, field: string, value: number) => {
        setSelectedPlanData(
            {
                ...selectedPlanData as PlanData,
                params: {
                    ...(selectedPlanData as PlanData)?.params,
                    dr_settings: (selectedPlanData as PlanData)?.params.dr_settings.map((setting, idx) => {
                        if (idx === i) {
                            return {
                                ...setting,
                                [field]: value
                            }
                        } else {
                            return {...setting}
                        }
                    })
                }
            }
        )
    }

    const handleClose = (refresh: boolean = false) => {
        setShowValidation(false);
        setValidName(true);
        setPlanName('');
        onClose(refresh);
        setInvalidAnchors(false);
        setInvalidDr(false);
        setShowDeleteDialog(false);
    }

    const removeDrSetting = (i: number) => {
        setSelectedPlanData(
            {
                ...selectedPlanData as PlanData,
                params: {
                    ...(selectedPlanData as PlanData)?.params,
                    dr_settings: (selectedPlanData as PlanData)?.params.dr_settings.filter((setting, idx) => idx !== i)
                }
            }
        )
    }

    const addDrSetting = () => {
        let updatedDrSettings = (selectedPlanData as PlanData)?.params.dr_settings.map((setting) => {
            return {...setting}
        });
        updatedDrSettings.push({
            from: null,
            to: null,
            percent: 1
        });
        setSelectedPlanData(
            {
                ...selectedPlanData as PlanData,
                params: {
                    ...(selectedPlanData as PlanData)?.params,
                    dr_settings: updatedDrSettings
                }
            }
        )
    }

    useEffect(() => {
        if (selectedPlanIndex > -2 && plans.length) {
            setSelectedPlanData(JSON.parse(JSON.stringify(plans[selectedPlanIndex === -1 ? (plans.length - 1) : selectedPlanIndex])));
        }
    }, [plans])

    const handleDeletePlan = async () => {
        setLoading(true);
        setSelectedPlanIndex(-2);
        deletePlan(selectedPlanData?.id as number).then(() => {
            handleClose(true);
        })
        
    }

    const handleSave = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShowValidation(true);
        let form = e.currentTarget as HTMLFormElement;
        if (selectedPlanIndex > -1) {
            if (form.checkValidity() && checkPercents()) {
                setLoading(true);
                setInvalidAnchors(false);
                setInvalidDr(false);
                updatePlan(selectedPlanData as PlanData, selectedPlanData?.id as number).then((updateRes) => {
                    if (updateRes.error) {
                        console.log(updateRes.message);
                        setLoading(false);
                        setValidName(false);
                    } else {
                        getPlans().then((res) => {
                            setPlans(res.data);
                            setLoading(false);
                            setShowValidation(false);
                        })
                    }
                    
                })
            }
        } else {
            if (planName) {
                setLoading(true);
                createPlan(planName).then((newPlanRes) => {
                    if (newPlanRes.error) {
                        setLoading(false);
                        setValidName(false);
                        setLoading(false);
                    } else {
                        getPlans().then((res) => {
                            setPlans(res.data);
                            setSelectedPlanIndex(res.data.length -1 );
                            setShowValidation(false);
                            setLoading(false);
                            setPlanName('');
                        })
                    }
                })
            }
        }
    }

    const checkPercents = () => {
        let valid = true;
        if (selectedPlanIndex > -1) {
            if (((selectedPlanData?.params.anchor_text_funnel_name_percent as number) + 
                (selectedPlanData?.params.anchor_text_natural_percent as number) + 
                (selectedPlanData?.params.anchor_text_urls_percent as number)) !== 100) {
                valid = false;
                setInvalidAnchors(true);
            } else {
                setInvalidAnchors(false);
            }
            let totalDr = selectedPlanData?.params.dr_settings.reduce((drSum, drSetting) => drSum += drSetting.percent, 0);
            if (totalDr !== 100) {
                valid = false;
                setInvalidDr(true);
            } else {
                setInvalidDr(false);
            }
        }
        return valid;
    }

    return (
        <>
            <Modal 
                show={open} 
                closeOnDocumentClick onHide={() => handleClose()}  
                dialogClassName='plans-modal' 
                // size='xl'
                animation={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Plans
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {loading ?
                <Container>
                    <div className="text-center">
                        <img src="/Spinner-1s-200px.gif" alt="" />
                    </div>
                </Container> 
                 : 
                    <>
                        <Container>
                        <Row>
                            <Col className='plan-buttons-cont'>
                                <Stack gap={3}>
                                    <div className={'plan-btn ' + (-1 === selectedPlanIndex ? ' active-plan' : '')} onClick={() => handleNewPlan()}>
                                        <FontAwesomeIcon
                                            icon={faFolderPlus}
                                            className="user-icon"
                                        />{' '}
                                        New Plan
                                    </div>
                                    {plans.map((plan, i) => 
                                        <div className={'plan-btn' + (i === selectedPlanIndex ? ' active-plan' : '')} onClick={() => selectPlan(i)}>{plan.name}</div>
                                    )}
                                </Stack>
                            </Col>
                            <Col xs={10}>
                                <div className='plan-editor-cont'>
                                <Form noValidate onSubmit={handleSave} validated={showValidation}>
                                <Container>
                                    
                                        {selectedPlanIndex > -2 
                                        ? 
                                        <>
                                            <Row className='mb-3 pb-3 align-items-center plan-form-section-end'>
                                                <Col>
                                                    <Form.Group as={Row} className='align-items-center' >
                                                        <Form.Label className='plan-header' htmlFor='name' column sm={3}>{selectedPlanIndex === -1 ? `New Plan Name ` : `Name `}</Form.Label>
                                                        <Col sm={6}>
                                                            <Form.Control 
                                                                id='name' 
                                                                name='name' 
                                                                type='text'
                                                                required
                                                                isInvalid={!validName}
                                                                value={selectedPlanIndex > -1 ? selectedPlanData?.name : planName}
                                                                onChange={(e) => handlePlanNameChange(e.target.value)} 
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Invalid or duplicate name
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                        
                                                    </Form.Group>
                                                </Col>
                                                {
                                                    selectedPlanIndex > -1 ? 
                                                    <>
                                                        <Col>
                                                            <Form.Group as={Row} className='align-items-center' >
                                                                <Form.Label htmlFor='priority' column sm={4}>Priority:</Form.Label>
                                                                <Col sm={6}>
                                                                <Form.Select 
                                                                    id='priority' 
                                                                    name='priority' 
                                                                    required
                                                                    value={selectedPlanData?.priority ?? ''} 
                                                                    onChange={(e) => handlePriorityChange(e.target.value)}
                                                                >
                                                                    <option value={''}></option>
                                                                    {priorityOpts.map((opt) => <option value={opt.value}>{opt.label}</option>)}
                                                                </Form.Select>
                                                                </Col>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Check 
                                                                type={'checkbox'}
                                                                id={`default`}
                                                                label={'Set as default'}
                                                                checked={selectedPlanData?.default}
                                                                onChange={(e) => handleDefault(e.target.checked)}
                                                            />
                                                        </Col>
                                                    </>
                                                    : <></>
                                                }
                                            </Row>
                                        </>
                                        :  
                                        null}
                                {selectedPlanIndex > -1 ? 
                                    <>
                                        <Row className='mb-2'><Col className='plan-header'>Link Settings</Col></Row>
                                        <Row className='mb-3 align-items-center'>
                                            <Col sm={3}>
                                                <span>DoFollow Links Amount</span>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Group as={Row} >
                                                    <Form.Label htmlFor='min_links_amount' column sm={4}>From</Form.Label>
                                                    <Col sm={5}>
                                                        <Form.Control 
                                                            id='min_links_amount' 
                                                            name='min_links_amount' 
                                                            type='number' 
                                                            min={1}
                                                            required
                                                            value={selectedPlanData?.params.min_links_amount ?? ''}
                                                            onChange={(e) => handlePlanChange(e.target.name, (e.target as HTMLInputElement).valueAsNumber)} 
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Group as={Row}  >
                                                    <Form.Label htmlFor='max_links_amount' column sm={4}>To</Form.Label>
                                                    <Col sm={5}>
                                                        <Form.Control 
                                                            id='max_links_amount' 
                                                            name='max_links_amount' 
                                                            type='number' 
                                                            min={1} 
                                                            required
                                                            value={selectedPlanData?.params.max_links_amount ?? ''}
                                                            onChange={(e) => handlePlanChange(e.target.name, (e.target as HTMLInputElement).valueAsNumber)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3 align-items-center'>
                                            <Col lg={3}>
                                                <span>No Follow Links</span>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group as={Row} >
                                                    <Form.Label htmlFor='max_no_follow' column sm={4}>Amount</Form.Label>
                                                    <Col sm={5}>
                                                        <Form.Control 
                                                            id='max_no_follow' 
                                                            name='max_no_follow' 
                                                            type='number' 
                                                            value={selectedPlanData?.params.max_no_follow ?? 0}
                                                            min={0}
                                                            required
                                                            onChange={(e) => handlePlanChange(e.target.name, (e.target as HTMLInputElement).valueAsNumber)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group as={Row} className='align-items-center'>
                                                    <Form.Label htmlFor='no_follow_min_dr' column sm={4}>Min. DR</Form.Label>
                                                    <Col sm={5}>
                                                        <Form.Control 
                                                            id='no_follow_min_dr' 
                                                            name='no_follow_min_dr' 
                                                            type='number' 
                                                            min={0}
                                                            required
                                                            value={selectedPlanData?.params.no_follow_min_dr ?? 0}
                                                            onChange={(e) => handlePlanChange(e.target.name, (e.target as HTMLInputElement).valueAsNumber)} 
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group as={Row} className='align-items-center'>
                                                    <Form.Label htmlFor='no_follow_max_price' column sm={4}>Max. Price</Form.Label>
                                                    <Col sm={5}>
                                                        <Form.Control 
                                                            id='no_follow_max_price' 
                                                            name='no_follow_max_price' 
                                                            type='number'
                                                            required
                                                            value={selectedPlanData?.params.no_follow_max_price ?? ''}
                                                            onChange={(e) => handlePlanChange(e.target.name, (e.target as HTMLInputElement).valueAsNumber)}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3 align-items-center pb-3 plan-form-section-end'>
                                            <Col lg={3}>
                                                <span className='input-percent-cont'>Budget</span>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group as={Row} className='align-items-center'>
                                                    <Form.Label htmlFor='max_price_budget_percent' column sm={4}>Max. link percentage</Form.Label>
                                                    <Col sm={6}>
                                                        <InputGroup>
                                                            <Form.Control 
                                                                id='max_price_budget_percent' 
                                                                name='max_price_budget_percent' 
                                                                type='number' 
                                                                value={selectedPlanData?.params.max_price_budget_percent ?? 0}
                                                                required
                                                                min={0}
                                                                onChange={(e) => handlePlanChange(e.target.name, (e.target as HTMLInputElement).valueAsNumber)}
                                                            />
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group as={Row} className='align-items-center'>
                                                    <Form.Label htmlFor='left_over_budget_percent' column sm={3}>Max. leftover budget percent</Form.Label>
                                                    <Col sm={3}>
                                                        <InputGroup>
                                                            <Form.Control 
                                                                id='left_over_budget_percent' 
                                                                name='left_over_budget_percent' 
                                                                type='number' 
                                                                required
                                                                min={0}
                                                                value={selectedPlanData?.params.left_over_budget_percent ?? 0}
                                                                onChange={(e) => handlePlanChange(e.target.name, (e.target as HTMLInputElement).valueAsNumber)}
                                                            />
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Col xs={'auto'} className='plan-header'>Anchor Text Settings</Col>
                                            {invalidAnchors ? <Col xs={'auto'} className='percentage-warning'>Invalid percentage settings, must about to 100%</Col> : null}
                                        </Row>
                                        <Row className='mb-3 align-items-center pb-3 plan-form-section-end'>
                                            <Col lg={3}>
                                                <span className='input-percent-cont'>Anchor text percentage</span>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group as={Row} >
                                                    <Form.Label htmlFor='anchor_text_natural_percent' column sm={4}>Natural</Form.Label>
                                                    <Col sm={6}>
                                                        <InputGroup>
                                                            <Form.Control 
                                                                id='anchor_text_natural_percent' 
                                                                name='anchor_text_natural_percent' 
                                                                type='number' 
                                                                required
                                                                min={0}
                                                                value={selectedPlanData?.params.anchor_text_natural_percent ?? ''}
                                                                onChange={(e) => handlePlanChange(e.target.name, (e.target as HTMLInputElement).valueAsNumber)} 
                                                                />
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group as={Row} >
                                                    <Form.Label htmlFor='anchor_text_urls_percent' column sm={4}>URLs</Form.Label>
                                                    <Col sm={6}>
                                                        <InputGroup>
                                                            <Form.Control 
                                                                id='anchor_text_urls_percent' 
                                                                name='anchor_text_urls_percent' 
                                                                type='number' 
                                                                required
                                                                min={0}
                                                                value={selectedPlanData?.params.anchor_text_urls_percent ?? ''}
                                                                onChange={(e) => handlePlanChange(e.target.name, (e.target as HTMLInputElement).valueAsNumber)}
                                                            />
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Group as={Row} className='align-items-center' >
                                                    <Form.Label htmlFor='anchor_text_funnel_name_percent' column sm={4}>Funnel Name</Form.Label>
                                                    <Col sm={6}>
                                                        <InputGroup>
                                                            <Form.Control 
                                                                id='anchor_text_funnel_name_percent' 
                                                                name='anchor_text_funnel_name_percent' 
                                                                type='number' 
                                                                required
                                                                min={0}
                                                                value={selectedPlanData?.params.anchor_text_funnel_name_percent ?? ''}
                                                                onChange={(e) => handlePlanChange(e.target.name, (e.target as HTMLInputElement).valueAsNumber)} 
                                                            />
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Col xs={'auto'} className='plan-header'>DR Settings</Col>
                                            {invalidDr ? <Col xs={'auto'} className='percentage-warning'>Invalid percentage settings, must about to 100%</Col> : null}
                                        </Row>
                                    
                                            {selectedPlanData?.params.dr_settings.map((drSetting, i) => {
                                                return <Row className='mb-3 align-items-center'>
                                                            <Col lg={3}>
                                                                <Form.Group as={Row} className='align-items-center' >
                                                                    <Form.Label htmlFor={`dr_percent_${i}`} column sm={6}>Link percentage</Form.Label>
                                                                    <Col sm={6}>
                                                                        <InputGroup>
                                                                            <Form.Control 
                                                                                id={`dr_percent_${i}`}  
                                                                                name={`dr_percent_${i}`} 
                                                                                type='number' 
                                                                                required
                                                                                min={1}
                                                                                value={selectedPlanData?.params.dr_settings[i].percent ?? ''}
                                                                                onChange={(e) => handlePlanDrChange(i, 'percent', (e.target as HTMLInputElement).valueAsNumber)}
                                                                            />
                                                                            <InputGroup.Text>%</InputGroup.Text>
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <Form.Group as={Row}  >
                                                                    <Form.Label htmlFor={`dr_from_${i}`} column sm={4}>From</Form.Label>
                                                                    <Col sm={8}>
                                                                        <Form.Control 
                                                                            id={`dr_from_${i}`}  
                                                                            name={`dr_from_${i}`}  
                                                                            type='number' 
                                                                            min={0} 
                                                                            value={selectedPlanData?.params.dr_settings[i].from ?? ''}
                                                                            onChange={(e) => handlePlanDrChange(i, 'from', (e.target as HTMLInputElement).valueAsNumber)}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <Form.Group as={Row}  >
                                                                    <Form.Label htmlFor={`dr_to_${i}`} column sm={3}>To</Form.Label>
                                                                    <Col sm={8}>
                                                                        <Form.Control 
                                                                            id={`dr_to_${i}`}  
                                                                            name={`dr_to_${i}`}  
                                                                            type='number' 
                                                                            min={0} 
                                                                            value={selectedPlanData?.params.dr_settings[i].to ?? ''}
                                                                            onChange={(e) => handlePlanDrChange(i, 'to', (e.target as HTMLInputElement).valueAsNumber)} 
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <div className='del-dr-btn input-percent-cont' onClick={() => removeDrSetting(i)}>
                                                                    <FontAwesomeIcon icon={faClose} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                            })}
                                        <Row className='pb-3 plan-form-section-end'>
                                            <Col md={12}>
                                                <div
                                                    className="add-dr-btn"
                                                    onClick={addDrSetting}
                                                    >
                                                    Add new setting{' '}
                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                </div>
                                            </Col>
                                            
                                        </Row>
                                        <Row>
                                            <FiltersC 
                                                columns={columns} 
                                                tableData={data.rows as TableDataRow[]} 
                                                prefilter={plans[selectedPlanIndex].filterOptions} 
                                                disableSearch={true} 
                                                disableViews={true} 
                                                useGroups={false} 
                                                dataLoading={false} 
                                                type='stash' 
                                                onChangeFilters={updatePlanFilters} 
                                            />     
                                        </Row>
                                    </>
                                
                                : <></>}
                                    </Container>
                                    <Container>
                                    {selectedPlanIndex > -2 ? 
                                        <Row className="justify-content-md-end">
                                        <Col xs="auto">
                                            <button className="btn new-board-btn" type='submit'>
                                                <FontAwesomeIcon
                                                    icon={faFolderPlus}
                                                />{' '}
                                                {selectedPlanIndex > -1 ? 'Save Plan' : 'Create Plan'}
                                            </button>
                                        </Col>
                                        {selectedPlanIndex > -1 ? 
                                            <Col md={2}>
                                                <Dropdown show={showDeleteDialog} onToggle={(isOpen) => setShowDeleteDialog(isOpen)} className='del-plan-dialog' drop='down'>
                                                    <Dropdown.Toggle className="btn btn-danger">
                                                        <FontAwesomeIcon
                                                            icon={faTrash}
                                                        />{' '}
                                                        Delete
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Container>
                                                            <Row className='mb-3'>
                                                                <Col><span style={{color: 'red', fontWeight: 600}}>Warning</span>: Are you sure you want to delete this plan? <br /> 
                                                                All Order Plans that use it and which haven't been executed yet will need to be adjusted!</Col>    
                                                            </Row>
                                                            <Row className='justify-content-md-space-between'>
                                                                <Button variant='danger' type='button'  onClick={handleDeletePlan} className='confirm-plan-del'>Yes, Delete Plan</Button>    
                                                                <Button variant='warning' type='button' onClick={() => setShowDeleteDialog(false)} className='cancel-plan-del'>No, Cancel</Button>
                                                            </Row>
                                                        </Container>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        : null}
                                    </Row>
                                    : null}
                                    </Container>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                        </Container>
                    </>
                }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Plans;